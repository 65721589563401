<template>
    <div class="page-wrapper">
        <PageSection>
            <div v-if="RETURN_URL.startsWith(PREPAY_OVERVIEW_URL)" class="d-flex flex-column align-items-center">
                <h1>{{ t('login.title_prepay') }}</h1>
                <p class="mb-3 mb-sm-4">{{ t('login.sub_title_prepay') }}</p>
                <p v-if="!!t('login.text_prepay')" class="mb-3 mb-sm-4 text-preline text-start">
                    {{ t('login.text_prepay') }}
                </p>
            </div>
            <div v-else-if="RETURN_URL.startsWith(POSTPAY_OVERVIEW_URL)" class="d-flex flex-column align-items-center">
                <h1>{{ t('login.title_postpay') }}</h1>
                <p class="mb-3 mb-sm-4">{{ t('login.sub_title_postpay') }}</p>
            </div>
            <div v-else-if="RETURN_URL.startsWith(GIFTCARD_OVERVIEW_URL)" class="d-flex flex-column align-items-center">
                <h1>{{ t('login.title_giftcard') }}</h1>
                <p class="mb-3 mb-sm-4">{{ t('login.sub_title_giftcard') }}</p>
                <p v-if="!!t('login.text_giftcard')" class="mb-3 mb-sm-4 text-preline text-start">
                    {{ t('login.text_giftcard') }}
                </p>
            </div>
            <div v-else>
                <h1>{{ t('login.title') }}</h1>
                <p class="mb-3 mb-sm-4">{{ t('login.sub_title') }}</p>
            </div>

            <form novalidate @submit.prevent="submitForm()" class="col-md-6 offset-md-3">
                <ErrorMessage :message="PHONE_SERVER_ERROR" />

                <div class="mb-3 test-phone-wrap">
                    <TextInput
                        :field="v$.phoneNumber"
                        :inputmode="'numeric'"
                        :pattern="'[0-9]*'"
                        :placeholder="t('login.phone_hint')"
                        :disabled="PHONE_PASSED"
                        :autocomplete="'tel-national'"
                    />

                    <ValidationErrors :formModel="v$.phoneNumber" />
                </div>

                <div class="mb-4 test-pin-wrap" v-if="PHONE_PASSED">
                    <p v-if="LOGIN_TYPE === 'OK'">
                        {{ t('login.login_type_ok') }}
                        <br />
                        <small class="fst-italic">{{ t('login.login_type_ok_note') }}</small>
                    </p>

                    <p v-if="LOGIN_TYPE === 'MANUAL'">
                        {{ t('login.login_type_manual') }}
                    </p>

                    <ErrorMessage :message="PINCODE_SERVER_ERROR" />

                    <TextInput
                        :field="v$.pinCode"
                        :inputmode="'numeric'"
                        :pattern="'[0-9]*'"
                        :placeholder="t('login.pin_hint')"
                        :id="'single-factor-code-text-field'"
                        :autocomplete="'one-time-code'"
                    />
                    <ValidationError :formModel="v$.pinCode" type="requiredIf" :message="t('login.pin_val_required')" />
                    <ValidationError :formModel="v$.pinCode" type="numeric" :message="t('login.pin_val_invalid')" />
                </div>

                <div class="mb-4">
                    <button class="btn btn-primary msd-form-submit" :disabled="PROCESSING">
                        {{ PHONE_PASSED ? t('login.submit_pin') : t('login.submit_phone') }}
                    </button>
                </div>
                <div>
                    <button @click.prevent="REENTER_PHONE()" v-if="PHONE_PASSED" class="btn btn-link">
                        {{ t('login.phone_re_enter') }}
                    </button>
                </div>
            </form>
            <div class="mt-3 mb-3">
                <img :src="postPaymentEngineLogo" class="msd-payment-logo" />
            </div>
        </PageSection>
    </div>
</template>

<script>
    import { reactive, computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter, useRoute } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import useVuelidate from '@vuelidate/core';
    import { required, requiredIf, numeric, helpers } from '@vuelidate/validators';
    import ValidationErrors from '../components/VuelidateForms/ValidationErrors.vue';
    import ValidationError from '../components/VuelidateForms/ValidationError.vue';
    import TextInput from '../components/VuelidateForms/TextInput.vue';
    import ErrorMessage from '../components/ErrorMessage.vue';
    import PageSection from '../components/PageSection.vue';
    import phoneFormats from '../utility/phone-formats';

    export default {
        setup() {
            const store = useStore();
            const router = useRouter();
            const route = useRoute();
            const { t } = useI18n();

            const postPaymentEngineLogo = store.getters['appSettings/POST_PAYMENT_ENGINE_LOGO'];
            const PHONE_PASSED = computed(() => store.getters['pageLogin/PHONE_PASSED']);

            const APP_LOCALE = store.getters['appSettings/APP_LOCALE'];
            const RETURN_URL = route.query.returnUrl ?? '';
            const PREPAY_OVERVIEW_URL = router.resolve({ name: 'Route_PrepayOverview' }).path;
            const POSTPAY_OVERVIEW_URL = router.resolve({ name: 'Route_PostpayOverview' }).path;
            const GIFTCARD_OVERVIEW_URL = router.resolve({ name: 'Route_GiftCardOverview' }).path;

            const formValues = reactive({
                phoneNumber: store.getters['pageLogin/PHONE_NUMBER'],
                pinCode: ''
            });
            const validationRules = computed(() => ({
                phoneNumber: {
                    required: helpers.withMessage(t('login.phone_val_required'), required),
                    numeric: helpers.withMessage(t('login.phone_val_invalid'), numeric),
                    localPhone: helpers.withMessage(
                        t('login.phone_val_local_phone_format'),
                        phoneFormats.GetLocalPhoneRule(APP_LOCALE)
                    )
                },
                pinCode: {
                    requiredIf: requiredIf(PHONE_PASSED),
                    numeric
                }
            }));
            const v$ = useVuelidate(validationRules, formValues);

            const submitForm = () => {
                v$.value.$touch();
                if (v$.value.$invalid) return;

                if (!PHONE_PASSED.value) {
                    store.dispatch('pageLogin/SUBMIT_PHONE', formValues.phoneNumber);
                    v$.value.$reset();
                } else {
                    store.dispatch('pageLogin/SUBMIT_PIN', formValues.pinCode);
                }
            };

            store.dispatch('pageLogin/ON_PAGE_LOAD');

            return {
                t,
                postPaymentEngineLogo,
                RETURN_URL,
                PREPAY_OVERVIEW_URL,
                POSTPAY_OVERVIEW_URL,
                GIFTCARD_OVERVIEW_URL,
                LOGIN_TYPE: computed(() => store.getters['pageLogin/LOGIN_TYPE']),
                PHONE_PASSED,
                PROCESSING: computed(() => store.getters['pageLogin/PROCESSING']),
                PHONE_SERVER_ERROR: computed(() => store.getters['pageLogin/PHONE_SERVER_ERROR']),
                PINCODE_SERVER_ERROR: computed(() => store.getters['pageLogin/PINCODE_SERVER_ERROR']),
                REENTER_PHONE: () => store.dispatch('pageLogin/REENTER_PHONE'),
                v$,
                submitForm
            };
        },
        components: { ValidationErrors, ValidationError, TextInput, ErrorMessage, PageSection }
    };
</script>
